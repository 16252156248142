<template>
  <main class="container">
    <ContentHeader
      :title="content_header.title"
      :subTitle="content_header.subTitle"
    />

    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <div class="page__body">
      <div class="page__body__header">
        <div class="row align-items-center justify-content-between">
          <div class="col-4">
            <label
              for="text"
              class="has-checkbox page__body__header__label text--capital"
              >Show deactivated notifications
              <input
                type="checkbox"
                id="text"
                class="form-"
                @change="toggleStatus()"
                v-model="showDeactive"
              />
              <span class="checkmark"></span>
            </label>
          </div>

          <div class="col-3">
            <select
              @change="getAllNotifications"
              class="select select--md"
              v-model="filterStatus"
            >
              <option :value="null">All</option>
              <option :value="true">Activated</option>
              <option :value="false">Deactivated</option>
            </select>
          </div>

          <div class="col-5">
            <button
              type="button"
              class="button ml-auto page__body__header__button button--black text--capital"
              @click="add('Create new')"
            >
              <span
                class="d-inline-flex mr-2"
                data-toggle="modal"
                data-target="#createNewGroup"
                >add predefined notification</span
              >
              <ion-icon name="add-outline" class="text--regular"></ion-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="mt5" style="width: 50%">
        <div class="form__item">
          <input
            type="text"
            class="input form__input form__input--lg"
            v-model="searchName"
            @input="performSearch"
            id="searchProduct"
            placeholder="Search for Predefined Notification Name"
            style="height: 40px"
          />
        </div>
      </div>
    </div>

    <table class="customtable w-100">
      <thead>
        <tr>
          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 19%"
          >
            <!-- group name -->
            name
          </th>

          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 19%"
          >
            members size
          </th>

          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 19%"
          >
            created by
          </th>

          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 19%"
          >
            status
          </th>

          <th
            scope="col"
            class="table__header__text table__text text--capital"
            style="width: 19%"
          >
            action
          </th>
        </tr>
      </thead>

      <tbody v-if="loading">
        <tr>
          <td colspan="12">
            <div class="d-flex align-items-center justify-content-center p5">
              <div
                class="spinner-border"
                style="width: 3rem; height: 3rem"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
              <span
                class="text--black text--600 text--medium d-inline-flex ml-4"
                >Please wait</span
              >
            </div>
          </td>
        </tr>
      </tbody>

      <tbody v-else-if="groupNotifications.data.length > 0">
        <tr
          v-for="(notification, index) in groupNotifications.data"
          :key="index"
        >
          <td>
            <div class="align-self-center">
              <span
                class="table__body__text table__text py-1 d-block text--upper"
              >
                {{ notification.groupName }}
              </span>
            </div>
          </td>
          <td>
            <div class="align-self-center">
              <span class="table__body__text table__text text--capital">{{
                notification.groupMembers.length
              }}</span>
            </div>
          </td>
          <td>
            <span
              class="table__body__text table__text py-1 d-block text--upper"
            >
              {{ notification.createdBy ? notification.createdBy : "" }}
            </span>
          </td>

          <td>
            <span
              v-if="notification.status == 'deactivated'"
              class="table__body__text table__text py-1 d-block text--upper"
              style="color: red"
            >
              {{ notification.status }}
            </span>
            <span
              v-else
              class="table__body__text table__text py-1 d-block text--upper"
              style="color: green"
            >
              {{ notification.status }}
            </span>
          </td>

          <td>
            <div class="tbody-child dropdown">
              <button
                class="button button--grey text--capital text--small d-flex align-items-center"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Action
                <span class="dropdown__icon">
                  <ion-icon name="caret-down-outline"></ion-icon>
                </span>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="edit('Edit', notification)"
                  data-toggle="modal"
                  data-target="#createNewGroup"
                >
                  Edit
                </a>
                <a
                  class="dropdown-item"
                  v-if="notification.status == 'deactivated'"
                  href="javascript:void(0)"
                  @click="
                    openActivationModal(
                      'activate',
                      notification.notificationKey
                    )
                  "
                >
                  Activate
                </a>
                <a
                  class="dropdown-item"
                  v-if="notification.status == 'activated'"
                  href="javascript:void(0)"
                  @click="
                    openActivationModal(
                      'deactivate',
                      notification.notificationKey
                    )
                  "
                >
                  Deactivate
                </a>
                <a
                  class="dropdown-item"
                  href="javascript:void(0)"
                  @click="
                    openActivationModal('delete', notification.notificationKey)
                  "
                >
                  Delete
                </a>
              </div>
            </div>
          </td>
        </tr>

        <!-- Pagination -->
        <Pagination
          :allItems="groupNotifications"
          :size="size"
          @update-created-task="changeLength"
          @change-page-index="changeCurrentPage"
        />
      </tbody>

      <tbody v-else>
        <tr>
          <td colspan="12">
            <div
              class="d-flex flex-column align-items-center justify-content-center p5"
            >
              <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

              <span
                class="d-block text--danger text--600 text--medium mt-3 mb-5"
                >No data available</span
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      class="modal modal__right"
      id="createNewGroup"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal__dialog" role="document">
        <div class="modal__content">
          <div class="modal__head">
            <div class="modal__head__icon">
              <a data-dismiss="modal" class="modal__close modal__close--black">
                <ion-icon
                  name="arrow-back-outline"
                  data-dismiss="modal"
                  id="formCloseButton"
                  aria-label="Close"
                  @click="clearForm"
                  :disabled="isSaving"
                ></ion-icon>
              </a>
            </div>
            <h5 class="modal__head__title text--capital">
              {{ createText }} Predefined Notification
            </h5>
          </div>
          <div class="modal__body">
            <form class="form" @submit="saveForm">
              <div class="form__item">
                <div class="form__item mb-0">
                  <!-- <label class="form__label">Group Name</label> -->
                  <label class="form__label">Name</label>
                  <input
                    type="text"
                    class="input form__input form__input--lg"
                    placeholder="Enter Name"
                    v-model="form.groupName"
                    v-bind:class="{
                      'input-required': formErr.groupName,
                    }"
                  />
                </div>
              </div>

              <div v-if="createText" class="row">
                <div class="col-6">
                  <div class="form__item">
                    <label class="form__label"
                      >Select Customers or Groups</label
                    >
                    <select
                      class="select select--lg"
                      required
                      v-model="selectedUser"
                      @change="changeSearchParams"
                      :disabled="createText == 'Edit'"
                    >
                      <option value="__Select option__" disabled selected>
                        __Select Type__
                      </option>
                      <option :value="1">Customers</option>
                      <option :value="2">Groups</option>
                    </select>
                  </div>
                </div>

                <transition name="slide-fade" appear>
                  <div v-if="allMembers.length > 0" class="col-6">
                    <div class="form__item">
                      <div class="form__item mb-0">
                        <label class="form__label">Select Group Members</label>
                        <multiselect
                          v-model="form.groupMembers"
                          placeholder="Search or add members"
                          :track-by="memberKey"
                          :options="allMembers"
                          :preserve-search="true"
                          :preselect-first="false"
                          :multiple="true"
                          :taggable="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :custom-label="customLabel"
                          class="select select--lg"
                        >
                          <span slot="noResult">Oops! No result found.</span>
                          <span slot="noOptions"
                            >Start typing to display list</span
                          >
                          <template slot="selection" slot-scope="{ isOpen }">
                            <span
                              class="multiselect__single"
                              v-if="form.groupMembers.length &amp;&amp; !isOpen"
                            >
                              {{ form.groupMembers.length }} options selected
                            </span>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>

              <div class="form__item mt-5">
                <div class="form__action">
                  <button
                    type="submit"
                    class="button form__button form__button--lg w-30 save-changes"
                    v-if="!isSaving"
                  >
                    {{ createText !== "Edit" ? "Create" : "Edit" }} Notification
                  </button>
                  <a
                    role="button"
                    class="button form__button form__button--lg w-25 save-changes"
                    v-if="isSaving"
                  >
                    <div class="spinner-border text-light" role="status"></div>
                  </a>
                  <a
                    role="button"
                    data-dismiss="modal"
                    class="modal__close form__action__text form__action__text--danger text--capital ml6"
                    @click="clearForm"
                    v-if="!isSaving"
                  >
                    cancel
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal :show="activateGroup" @close="closeActivationModal">
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-6">
              {{ modalHead }} this Predefined Notification
            </h5>
            <p>{{ message }}</p>
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button button--md modal__confirm__button modal__confirm__button--cancel mr-2"
                @click="closeActivationModal"
              >
                cancel
              </button>

              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes fitrem"
                v-if="isActivating"
              >
                <div class="spinner-border texxt-light" role="status"></div>
              </button>
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--md save-changes modal__confirm__button--delete fitrem"
                @click="activate"
                v-else
              >
                {{ modalHead }}
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>
  </main>
</template>
<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import customerService from "@/core/services/general-setup-service/customer.service";
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import { mapState } from "vuex";
import { GET_ALL_GROUP_NOTIFICATIONS } from "@/core/services/store/notification.module";
export default {
  name: "PredefinedNotification",
  components: {
    Pagination: () =>
      import(
        /* WebpackChunkName: "Pagination" */ "@/components/UI/pagination.vue"
      ),
    Multiselect,
    DeleteModal,
  },
  mounted() {
    this.getCustomers();
    this.getGroups();
    this.getAllNotifications();
  },
  data() {
    return {
      content_header: {
        title: "Predefined Notifications",
        subTitle: `Create and Manage your notifications destination.`,
      },
      isSaving: false,
      form: {
        groupName: null,
        groupMembers: [],
      },
      allCustomers: [],
      allGroups: [],
      allMembers: [],
      formErr: {
        groupName: null,
      },
      fetchModel: {
        pageIndex: 1,
        pageSize: 100000,
        customerTypeKey: null,
        filters: [],
        customerStatus: null,
      },
      selectedUser: "__Select option__",
      show: false,
      memberKey: "",
      alert: {
        status: "",
        title: "",
        description: "",
        show: false,
      },
      size: 20,
      pages: 1,
      activateGroup: false,
      message: "",
      modalHead: "",
      isActivating: false,
      status: "",
      createText: "",
      groupKey: "",
      showDeactive: false,
      filterStatus: null,
      searchName: "",
    };
  },
  methods: {
    clearForm() {
      this.allMembers = [];
      (this.show = false),
        (this.memberKey = ""),
        (this.selectedUser = "__Select option__"),
        (this.form = {
          groupName: null,
          groupMembers: [],
        });
    },
    edit(createText, data) {
      this.clearForm();
      this.createText = createText ? createText : "";
      this.form.groupName = data.groupName;
      this.form.data = data;
      if (
        data.groupMembers[0].memberType.toLowerCase() == "customer" ||
        data.groupMembers[0].memberType.toLowerCase() == "customers"
      ) {
        this.selectedUser = 1;
        this.allMembers = this.allCustomers;
        this.memberKey = "customerKey";
        data.groupMembers.forEach((member) => {
          let exist = this.allCustomers.find(
            (customer) => customer.customerKey == member.memberKey
          );
          if (exist) {
            this.form.groupMembers.push(exist);
          }
        });
      } else if (
        data.groupMembers[0].memberType.toLowerCase() == "group" ||
        data.groupMembers[0].memberType.toLowerCase() == "groups"
      ) {
        this.selectedUser = 2;
        this.allMembers = this.allGroups;
        this.memberKey = "groupKey";
        data.groupMembers.forEach((member) => {
          let exist = this.allGroups.find(
            (group) => group.groupKey == member.memberKey
          );
          if (exist) {
            this.form.groupMembers.push(exist);
          }
        });
      }
    },
    performSearch() {
      this.getAllNotifications();
    },
    add(createText) {
      this.clearForm();
      this.createText = createText ? createText : "";
    },
    toggleStatus() {
      if (this.showDeactive) {
        this.filterStatus = false;
      } else {
        this.filterStatus = null;
      }
      this.getAllNotifications();
    },
    getAllNotifications() {
      this.filterStatus == false
        ? (this.showDeactive = true)
        : (this.showDeactive = false);
      this.$store.dispatch(GET_ALL_GROUP_NOTIFICATIONS, {
        pageindex: this.pages,
        pagesize: this.size,
        status: this.filterStatus,
        searchName: this.searchName,
      });
    },
    closeActivationModal() {
      this.activateGroup = false;
      this.status = "";
      this.groupKey = "";
    },
    openActivationModal(status, key) {
      if (status == "delete") {
        this.message =
          "Please confirm that you want to delete this predefined notification";
        this.modalHead = "Delete";
      } else if (status == "activate") {
        this.message =
          "Please confirm that you want to activate this predefined notification";
        this.modalHead = "Activate";
      } else {
        this.message =
          "Please confirm that you want to deactivate this predefined notification";
        this.modalHead = "Deactivate";
      }
      this.activateGroup = true;
      this.status = status;
      this.groupKey = key;
    },
    activate() {
      this.isActivating = true;
      if (this.status == "delete") {
        this.deleteNotification();
      } else if (this.status == "activate") {
        this.activateNotification();
      } else {
        this.deactivateNotification();
      }
    },
    deleteNotification() {
      ApiService.delete(
        `NotificationMessage/DeletePredefinedNotificationGroup?groupKey=${this.groupKey}`
      )
        .then((response) => {
          this.getAllNotifications();
          if (response.data.status) {
            this.alertSuccess(response.data.message);
          } else {
            this.alertError(response.data.message);
          }
          this.isActivating = false;
          this.closeActivationModal();
        })
        .catch((err) => {
          this.isActivating = false;
          this.alertError(err.response.data.message);
        });
    },
    activateNotification() {
      ApiService.put(
        `NotificationMessage/ActivatePredefinedNotificationGroup?groupKey=${this.groupKey}`
      )
        .then((response) => {
          this.getAllNotifications();
          if (response.data.status) {
            this.alertSuccess(response.data.message);
          } else {
            this.alertError(response.data.message);
          }
          this.isActivating = false;
          this.closeActivationModal();
        })
        .catch((err) => {
          this.isActivating = false;
          this.alertError(err.response.data.message);
        });
    },
    deactivateNotification() {
      ApiService.put(
        `NotificationMessage/DeactivatePredefinedNotificationGroup?groupKey=${this.groupKey}`
      )
        .then((response) => {
          this.getAllNotifications();
          if (response.data.status) {
            this.alertSuccess(response.data.message);
          } else {
            this.alertError(response.data.message);
          }
          this.isActivating = false;
          this.closeActivationModal();
        })
        .catch((err) => {
          this.isActivating = false;
          this.alertError(err.response.data.message);
        });
    },
    changeCurrentPage(pages = null, pageSize) {
      if (pages === "next") {
        this.pages++;
        this.size = pageSize;
        this.getAllNotifications();
      } else if (pages === "previous") {
        this.pages--;
        this.size = pageSize;
        this.getAllNotifications();
      } else if (pages !== null) {
        this.parameters.pageIndex = pages;
        this.pages = pages;
        this.size = pageSize;
        this.getAllNotifications();
      }
    },

    changeLength(value) {
      this.size = value;
      this.getAllNotifications();
    },
    saveForm() {
      if (
        this.form.groupName == null ||
        this.form.groupName == "" ||
        this.form.groupMembers.length < 1
      ) {
        this.alertError(
          "You need to select a group name and group member(s) before submitting"
        );
        return;
      } else {
        this.isSaving = true;
        let groupMembers = [];

        this.form.groupMembers.forEach((member) => {
          let key =
            this.selectedUser === 1 ? member.customerKey : member.groupKey;
          let obj = {};
          let existMember =
            this.createText == "Edit"
              ? this.form.data.groupMembers.find((mem) => mem.memberKey === key)
              : null;
          if (existMember) {
            obj = existMember;
            obj.memberName =
              this.selectedUser === 1
                ? `${member.customerFirstName} ${member.customerLastName}`
                : member.groupName;
          } else {
            obj.memberKey =
              this.selectedUser === 1 ? member.customerKey : member.groupKey;
            obj.memberType = this.selectedUser === 1 ? "Customer" : "Group";
            obj.notificationGroupMemberKey = null;
            obj.notificationKey = null;
            obj.memberName =
              this.selectedUser === 1
                ? `${member.customerFirstName} ${member.customerLastName}`
                : member.groupName;
          }

          groupMembers.push(obj);
        });

        let data = {
          groupName: this.form.groupName,
          groupMembers,
          groupKey:
            this.createText == "Edit" ? this.form.data.notificationKey : null,
        };
        if (this.createText !== "Edit") {
          ApiService.post(
            "NotificationMessage/CreatePredefinedNotificationGroup",
            data
          )
            .then((response) => {
              this.getAllNotifications();
              this.isSaving = false;
              if (response.data.status == true) {
                this.alertSuccess(response.data.message);
              } else {
                this.alertError(response.data.message);
              }
              this.clearForm();
              document.getElementById("formCloseButton").click();
            })
            .catch((err) => {
              this.alertError(err);
            });
        } else if (this.createText !== "Create") {
          ApiService.put(
            `NotificationMessage/UpdatePredefinedNotificationGroup?groupKey=${this.form.data.notificationKey}`,
            data
          )
            .then((response) => {
              this.getAllNotifications();
              this.isSaving = false;
              if (response.data.status == true) {
                this.alertSuccess(response.data.message);
              } else {
                this.alertError(response.data.message);
              }
              this.clearForm();
              document.getElementById("formCloseButton").click();
            })
            .catch((err) => {
              this.alertError(err);
            });
        }
      }
    },
    async getCustomers() {
      try {
        customerService.getCustomers(this.fetchModel).then((res) => {
          this.allCustomers = res.data.data.items;
        });
      } catch (e) {
        // const error = e.response.data.message;
        // this.alertError(error);
      }
    },
    getGroups() {
      GeneralApiService.post("Group/Groups", this.fetchModel).then((resp) => {
        this.allGroups = resp.data.data.items;
      });
    },
    changeSearchParams() {
      this.allMembers = [];
      this.memberKey = "";
      this.show = false;
      if (this.selectedUser === 1) {
        this.show = true;
        this.allMembers = this.allCustomers;
        this.form.groupMembers = [];
        this.memberKey = "customerKey";
      } else if (this.selectedUser === 2) {
        this.allMembers = this.allGroups;
        this.show = true;
        this.form.groupMembers = [];
        this.memberKey = "groupKey";
      } else {
        this.allMembers = [];
        this.show = false;
        this.memberKey = "";
        this.form.groupMembers = [];
      }
    },
    customLabel(option) {
      if (option.groupName) {
        return `${option.groupName}`;
      } else if (option.customerFirstName || option.customerLastName) {
        return `
          ${option.customerFirstName ? option.customerFirstName : ""} 
          ${option.customerLastName ? option.customerLastName : ""}
        `;
      } else {
        return "";
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },

    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  computed: {
    ...mapState({
      groupNotifications: (state) => {
        return state.notification.allGroupNotifications?.data;
      },
      paginations: (state) => {
        return state.notification.allGroupNotifications;
      },
      loading: (state) => {
        return state.notification.loading;
      },
    }),
  },
};
</script>

<style scoped></style>
